import React, { useEffect } from "react";
import { string, array } from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { getAdSlots, initApsTag } from "../utils";

import "./dfp-component.m.css";

const DfpComponent = ({ adStyleName, id, size, path, type = "", viewPortSizeMapping }) => {
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const adsConfig = get(qtState, ["config", "ads-config"], {});
  const enableAds = get(adsConfig, ["dfp_ads", "enable_ads"], null);
  const pubID = get(adsConfig, ["pub_id"], null);
  const currentPath = get(qtState, ["currentPath"], null);

  if (!enableAds) return null;

  useEffect(() => {
    getAdSlots({
      path,
      size,
      id,
      qtState,
      type,
      viewPortSizeMapping
    });
  }, [currentPath, id]);

  useEffect(() => {
    if (pubID) {
      initApsTag(pubID, id);
    }
  }, [pubID]);

  return <div styleName={`ad-slot ${adStyleName} `} id={id} />;
};

DfpComponent.propTypes = {
  adStyleName: string,
  id: string,
  size: array,
  path: string,
  type: string,
  viewPortSizeMapping: array
};

export { DfpComponent };
