import get from "lodash/get";

const getStoryTemplate = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "story-template"], "text");
  } else {
    return "";
  }
};

const getSectionSlug = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "sections", 0, "slug"], "NA");
  } else if (pageType === "section-page" || pageType === "sub-section-page") {
    return get(state, ["data", "section", "slug"], "NA");
  }
  return "NA";
};

const getSectionId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "sections", 0, "id"], "NA");
  } else if (pageType === "section-page" || pageType === "sub-section-page") {
    return get(state, ["data", "section", "id"], "NA");
  }
  return "NA";
};

const getStoryId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "public-identifier"], "NA");
  }
  return "NA";
};

const getSectionList = (state, pageType) => {
  if (pageType === "story-page") {
    const sections = get(state, ["data", "story", "sections"], []);
    const sectionList = sections.map((item) => item.name);
    return sectionList;
  }

  return [];
};

const getTagList = (state, pageType) => {
  if (pageType === "story-page") {
    const tags = get(state, ["data", "story", "tags"], []);
    const tagList = tags.map((item) => item.name);
    return tagList;
  }

  return [];
};

export const setViewportSizeMapping = (adSlot, googletag, viewPortSizeMapping) => {
  if (!viewPortSizeMapping.length) return;

  const mapping = googletag
    .sizeMapping()
    .addSize(viewPortSizeMapping[0].viewport, viewPortSizeMapping[0].sizes)
    .addSize(viewPortSizeMapping[1].viewport, viewPortSizeMapping[1].sizes)
    .build();

  adSlot.defineSizeMapping(mapping);
};

export const generateLazyLoading = (googletag, fetchMarginPercent, renderMarginPercent, mobileScaling) => {
  const updateSlotStatus = (slotId, state) => {
    const elem = document.getElementById(slotId + "-" + state);
    if (elem) {
      elem.className = "activated";
      elem.innerText = "Yes";
    }
  };

  if (googletag && googletag.pubadsReady) {
    // detect whether PubAdsService is fully loaded
    googletag.pubads().enableLazyLoad({
      fetchMarginPercent, // Fetch slots within specified viewports
      renderMarginPercent, // Render slots within specified viewports
      mobileScaling // Multiplies the specified value with the above values for mobile
    });

    googletag.pubads().addEventListener("slotRequested", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "fetched");
    });

    googletag.pubads().addEventListener("slotOnload", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "rendered");
    });
  }
};

export const setTargetingParams = (googletag, adSlot, qtState, storySectionSlug) => {
  const pageType = get(qtState, ["pageType"], "");
  const environment = get(qtState, ["config", "publisher-attributes", "env"], "");
  const currentPath = get(qtState, ["currentPath"], "");
  const sectionSlug = storySectionSlug || getSectionSlug(qtState, pageType);
  const sectionId = getSectionId(qtState, pageType);
  const storyId = getStoryId(qtState, pageType);
  const sectionList = getSectionList(qtState, pageType);
  const tagList = getTagList(qtState, pageType);
  const storyTemplate = getStoryTemplate(qtState, pageType);

  adSlot
    .addService(googletag.pubads())
    .setTargeting("pageType", pageType)
    .setTargeting("environment", environment)
    .setTargeting("currentPath", currentPath)
    .setTargeting("sectionSlug", sectionSlug)
    .setTargeting("sectionId", sectionId)
    .setTargeting("storyId", storyId)
    .setTargeting("sectionList", sectionList)
    .setTargeting("tagList", tagList)
    .setTargeting("storyTemplate", storyTemplate)
    .setTargeting("publisherName", "Prajavani");
};

export const useDfpSlot = ({ path, size, id, qtState, viewPortSizeMapping, storySectionSlug, refreshAdUnit }) => {
  if (refreshAdUnit && window.googletag && window.googletag.apiReady) {
    // check if the API is ready
    window.googletag.cmd.push(function () {
      if (window.googletag.pubadsReady) {
        // detect whether PubAdsService is fully loaded
        const existingSlot = window.googletag
          .pubads()
          .getSlots()
          .filter((item) => id === item.getSlotElementId());

        window.googletag.pubads().refresh(existingSlot);
      }
    });
  }

  window.googletag.cmd.push(function () {
    const responsiveAdSlot = window.googletag.defineSlot(path, size, id);

    if (responsiveAdSlot) {
      setTargetingParams(window.googletag, responsiveAdSlot, qtState, storySectionSlug);
      if (viewPortSizeMapping) {
        setViewportSizeMapping(responsiveAdSlot, window.googletag, viewPortSizeMapping);
      }
      // eslint-disable-next-line no-undef
      window.googletag.pubads().disableInitialLoad();
      googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();
    }
  });

  window.googletag.cmd.push(function () {
    window.googletag.display(id);
  });
};

export const initApsTag = (pubID, slotId) => {
  // check if apstag is already loaded
  // if (!window?.apstag) {
  // const apstagScript = document.createElement("script");
  // apstagScript.async = true;
  // apstagScript.src = "//c.amazon-adsystem.com/aax2/apstag.js";

  // const head = document.getElementsByTagName("head")[0];
  // head.insertBefore(apstagScript, head.firstChild);

  // load the apstag.js library
  !(function (a9, a, p, s, t, A, g) {
    if (a[a9]) return;
    function q(c, r) {
      a[a9]._Q.push([c, r]);
    }
    a[a9] = {
      init: function () {
        q("i", arguments);
      },
      fetchBids: function () {
        q("f", arguments);
      },
      setDisplayBids: function () {},
      targetingKeys: function () {
        return [];
      },
      _Q: []
    };
    A = p.createElement(s);
    A.async = !0;
    A.src = t;
    g = p.getElementsByTagName(s)[0];
    g.parentNode.insertBefore(A, g);
  })("apstag", window, document, "script", "//c.amazon-adsystem.com/aax2/apstag.js");
  console.log(pubID, "pub id");
  // initialize apstag
  window?.apstag?.init({
    pubID: pubID,
    adServer: "googletag",
    bidTimeout: 2e3
  });

  // request bids for the specified slots
  window?.apstag?.fetchBids(
    {
      slots: [
        {
          slotID: "div-gpt-ad-1675836921322-0",
          slotName: "1013527/PV_Desktop_HP_RT",
          sizes: [[300, 250]]
        },
        {
          slotID: "div-gpt-ad-1675836977581-0",
          slotName: "1013527/PV_Desktop_HP_Billboard_Mid01",
          sizes: [
            [970, 250],
            [970, 90],
            [728, 90]
          ]
        },
        {
          slotID: "div-gpt-ad-1683278304115-0",
          slotName: "1013527/PV_Desktop_HP_Billboard_Mid02",
          sizes: [
            [970, 250],
            [970, 90],
            [728, 90]
          ]
        },
        {
          slotID: "div-gpt-ad-1675837026472-0",
          slotName: "1013527/PV_Desktop_HP_RM",
          sizes: [
            [300, 250],
            [300, 600]
          ]
        },
        {
          slotID: " div-gpt-ad-1675837126868-0",
          slotName: "1013527/PV_Desktop_AP_RT",
          sizes: [[300, 250]]
        },
        {
          slotID: "div-gpt-ad-1675837175211-0",
          slotName: "1013527/PV_Desktop_AP_Mid",
          sizes: [
            [1, 1],
            [300, 250]
          ]
        },
        {
          slotID: " div-gpt-ad-1675837205470-0",
          slotName: "1013527/PV_Desktop_AP_RM",
          sizes: [
            [300, 250],
            [300, 600]
          ]
        },
        {
          slotID: "div-gpt-ad-1675837369206-0",
          slotName: "1013527/PV_MWeb_HP_Top",
          sizes: [
            [336, 280],
            [300, 250]
          ]
        },
        {
          slotID: "div-gpt-ad-1675837402097-0",
          slotName: "1013527/PV_MWeb_HP_Mid",
          sizes: [
            [336, 280],
            [300, 250]
          ]
        },
        {
          slotID: "div-gpt-ad-1675837430268-0",
          slotName: "1013527/PV_MWeb_HP_Bottom",
          sizes: [
            [336, 280],
            [300, 250]
          ]
        },
        {
          slotID: "div-gpt-ad-1675837460755-0",
          slotName: "1013527/PV_Mweb_HP_Sticky",
          sizes: [[320, 50]]
        },
        {
          slotID: "div-gpt-ad-1675837489260-0",
          slotName: "1013527/PV_MWeb_AP_Top",
          sizes: [
            [336, 280],
            [300, 250]
          ]
        },
        {
          slotID: "div-gpt-ad-1675837516069-0",
          slotName: "1013527/PV_MWeb_AP_Mid",
          sizes: [
            [336, 280],
            [300, 250]
          ]
        },
        {
          slotID: "div-gpt-ad-1675837544367-0",
          slotName: "1013527/PV_MWeb_AP_Bottom",
          sizes: [
            [336, 280],
            [300, 250]
          ]
        },
        {
          slotID: "div-gpt-ad-1675837571080-0",
          slotName: "1013527/PV_Mweb_AP_Sticky",
          sizes: [[320, 50]]
        }
      ]
    },
    function (bids) {
      // set apstag bids, then trigger the first request to GAM
      window?.googletag?.cmd?.push(function () {
        window?.apstag?.setDisplayBids();
        const slot = window.googletag
          .pubads()
          .getSlots()
          .find((item) => {
            return item.getSlotElementId() === slotId;
          });

        if (slot) {
          window.googletag.pubads().refresh([slot]);
        }
        // window?.googletag?.pubads()?.refresh();
      });
    }
  );
  // }
};

export const getAdSlots = ({ path, size, id, qtState, type, viewPortSizeMapping, storySectionSlug, refreshAdUnit }) => {
  useDfpSlot({
    path: path,
    size: size,
    id: id,
    qtState: qtState,
    type: type,
    viewPortSizeMapping: viewPortSizeMapping,
    storySectionSlug,
    refreshAdUnit
  });
};
